import React from 'react';
import Feature from '../../components/feature/Feature';
import './services.css';
import build from '../../assets/buildD-05.png';
import migrate from '../../assets/importD-09.png';
import devops from '../../assets/DevOpsD-08.png';
import cost from '../../assets/costD-07.png';
import consulting from '../../assets/consultingD-06.png';
import security from '../../assets/securityD-05.png';


const servicesData = [
    {
        title: 'Application Implementation',
        text: 'Build highly scalable applications using cloud native services',
        icon: build
    },
    {
        title: 'Cloud Migration',
        text: 'Show you the most efficient way to migrate your workloads to the cloud',
        icon: migrate
    },
    {
        title: 'DevOps',
        text: 'Give you hands-on help with the build and release process',
        icon: devops
    },
    {
        title: 'Security & Compliance',
        text: 'Ensure that your infrastructure follows the security best practices',
        icon: security
    },
    {
        title: 'Strategic Consulting',
        text: 'Offer architectural guidance on starting and optimizing your projects',
        icon: consulting
    }
]

const Services = () => {
    return (
        <div className='team section__padding' id='services'>
            <div className='team-heading'>
                <h1 className='gradient__text'>Our Services</h1>
            </div>
            <div className='services-container'>
                {servicesData.map((item, index) => (
                    <Feature icon={item.icon} iconSize={item.size} title={item.title} text={item.text} key={item.title + index}/>
                ))}
            </div>
        </div>
    )
}

export default Services;
