import React from 'react';
import './feature.css';

const Feature = ({ title, text, icon }) => {
    return (
        <div className='features-container__feature'>
            <div className='service-icons'>
              <img src={icon}/>
            </div>
            <div className='features-container__feature-title'>
                <h1>{title}</h1>
            </div>
            <div className='features-container_feature-text'>
                <p>{text}</p>
            </div>
        </div>
    )
}

export default Feature;
