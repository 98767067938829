import React from 'react';
import Biography from '../../components/biography/Biography';
import Z  from '../../assets/vkbe-Z.png';
import DH from '../../assets/vkbe-DH.png';
import './team.css';

const Team = () => {
    return (
        <div className='team section__padding' id='team'>
            <div className='team-heading'>
              <h1 className='gradient__text'>Meet our team</h1>
            </div>
            <div className='team-container'>
              <Biography name='Z. Porobic' status='' text='Application Specialist' image={Z}/>
              <Biography name='D. Do' status='' text='Infrastructure Specialist' image={DH}/>
              <Biography name='S. Kurra' status='' text='Build and Release Specialist'/>
              <Biography name='R. Rosaldo' status='' text='Security Specialist'/>
              <Biography name='N. Imani' status='' text='Data Specialist'/>
              <Biography name='H. Do' status='' text='Front-End Specialist'/>
              <Biography name='A. Odesile' status='' text='Back-End Specialist'/>
              <Biography name='M. Nguyen' status='' text='Business Analyst'/>
            </div>
        </div>
    )
}

export default Team;
